'use strict';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var url = require('url');
var qs = require('querystring');
var coreModule = require('./../core');

/**
 * Authorization Code flow implementation
 */
module.exports = function (config) {
  var core = coreModule(config);
  var tokenUrl = url.resolve(config.auth.tokenHost, config.auth.tokenPath);
  var authorizeUrl = url.resolve(config.auth.authorizeHost, config.auth.authorizePath);

  /**
   * Redirect the user to the autorization page
   * @param  {String} params.redirectURI A string that represents the registered application URI
   *                                     where the user is redirected after authentication
   * @param {String} params.scope A String that represents the application privileges
   * @param {String} params.state A String that represents an option opaque value used by the client
   *                              to main the state between the request and the callback
   * @return {String} the absolute authorization url
   */
  function authorizeURL(params) {
    var options = Object.assign({}, _defineProperty({
      response_type: 'code'
    }, config.client.idParamName, config.client.id), params);

    return authorizeUrl + '?' + qs.stringify(options);
  }

  /**
   * Returns the Access Token Object
   * @param  {String} params.code Authorization code (from previous step)
   * @param  {String} params.redirecURI A string that represents the callback uri
   * @param  {Function} callback
   * @return {Promise}
   */
  function getToken(params, callback) {
    var options = Object.assign({}, params, {
      grant_type: 'authorization_code'
    });

    return core.api('POST', tokenUrl, options).nodeify(callback);
  }

  return {
    authorizeURL: authorizeURL,
    getToken: getToken
  };
};