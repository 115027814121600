import React from "react";

import DesktopSidebar from "../../DesktopSidebar";

const AppScreenWrapper = ({hasSidebar, children}) => (
    <div className="App-Main">

        {!!hasSidebar && (
            <div className="App-Left-Sidebar">
                <DesktopSidebar />
            </div>
        )}

        <div className="App-Content">
            {children}
        </div>
    </div>
);

export default AppScreenWrapper;