import React, {Component} from "react";
import {connect} from "react-redux";

import {qrCodeUpdate} from "../../actions/qrCode";
import LoadIndicator from "../../components/General/LoadIndicator";

import "./QrCode.css";

class QrCode extends Component {
    componentDidMount() {
        const { qrCode, qrCodeUpdate } = this.props;

        const checkAndUpdateQrCode = () => {
            if (!qrCode.isFetching && qrCode.qrCodeData === null) {
                qrCodeUpdate();
            }
        };

        checkAndUpdateQrCode();
        this.updateInterval = setInterval(checkAndUpdateQrCode, 30000);
    }

    componentWillUnmount() {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    }

    render() {
        const { qrCode } = this.props;
        return (
            <div className="QrCode">
                <p>
                    Scan onderstaande QR code om onze app te gebruiken
                </p>

                { qrCode.qrCodeData === null && qrCode.isFetching && (
                    <LoadIndicator message="Bezig met genereren van uw persoonlijke inlogcode" />
                )}

                { qrCode.qrCodeData !== null && !qrCode.hasError && (
                    <img src={qrCode.qrCodeData} alt={"Scan deze QR code om mobiel in te loggen"} className="QrCode-Image" />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    qrCode : state.qrCode
});

const mapDispatchToProps = {
    qrCodeUpdate
};

export default connect(mapStateToProps, mapDispatchToProps)(QrCode);